import React from "react";

const Billing_Renewals = () => {
  const emailAddress = "support@docusensa.com";
  const mailtoLink = `mailto:${emailAddress}`;
  return (
    <div>
      <h1 className="text-xl font-bold text-white mt-6">
        Payment Method, Billing History & Usage
      </h1>
      <h3 className="text-gray-400">
        Manage your payment method and billing history
      </h3>
      <div className="border border-white mt-4"></div>

      {/* Billing Part */}
      <div className="flex flex-col md:flex-row  gap-10 mt-6">
        <div className="md:w-1/5 w-full ">
          <h2 className="text-xl font-bold text-white">Invoice History</h2>
          <h3 className="text-gray-400 ">
            Please reach out to our team via{" "}
            <a className="text-purple-500" href={mailtoLink}>
              support@docusensa.com
            </a>{" "}
            if you have any concerns about billing
          </h3>
        </div>
        <div className="md:w-2/3 w-full ">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-white uppercase  bg-purple-800">
                <tr className="border-b-gray-200">
                  <th scope="col" className="px-6 py-3">
                    <div className="flex items-center">
                      Invoice
                      <a href="#">
                        <svg
                          className="w-3 h-3 ml-1.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Plan
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className=" border-b-gray-200 bg-gray-800 text-white">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                  >
                    Invoice 1
                  </th>
                  <td className="px-6 py-4">Paid</td>
                  <td className="px-6 py-4">$60</td>
                  <td className="px-6 py-4">Pro</td>
                </tr>
                <tr className="border-b-gray-200 bg-gray-800 text-white">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                  >
                    Invoice 2
                  </th>
                  <td className="px-6 py-4">Paid</td>
                  <td className="px-6 py-4">$80</td>
                  <td className="px-6 py-4">Pro</td>
                </tr>
                <tr className="border-b-gray-200 bg-gray-800 text-white">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                  >
                    Invoice 3
                  </th>
                  <td className="px-6 py-4">Paid</td>
                  <td className="px-6 py-4">$100</td>
                  <td className="px-6 py-4">Pro</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col md:flex-row gap-10 mt-10">
        <div className="md:w-1/5 w-full">
          <h2 className="text-xl font-bold text-white">Usage</h2>
          <h3 className="text-gray-400 mt-2">Track your Usage</h3>
        </div>
        <div className="md:w-2/3 w-full">
          <div className=" mx-22 grid gap-10 lg:grid-cols-2 justify-between ">
            <div className="flex flex-col gap-5  max-w-sm p-6  border border-gray-700 rounded-lg shadow hover:bg-gray-700 bg-gray-800">
              <div>
                <h4 className="mb-4 text-2xl font-bold tracking-tight text-white">
                  Uploaded Docs
                </h4>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-white">
                  0 / 2
                </h5>
              </div>
              <div className="mt-2 text-end" >
                <button className="font-normal border border-gray-300 px-4 py-1 rounded-full text-green-300">
                  0.00% Quota Used
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-5  max-w-sm p-6  border border-gray-700 rounded-lg shadow hover:bg-gray-700 bg-gray-800">
              <div>
                <h4 className="mb-4 text-2xl font-bold tracking-tight text-white">
                Conversations
                </h4>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-white">
                  0
                </h5>
              </div>
              <div className="mt-2 text-end" >
                <button className="font-normal border border-gray-300 px-4 py-1 rounded-full text-green-300">
                  0.00% Quota Used
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-5  max-w-sm p-6  border border-gray-700 rounded-lg shadow hover:bg-gray-700 bg-gray-800">
              <div>
                <h4 className="mb-4 text-2xl font-bold tracking-tight text-white">
                Questions
                </h4>
                <h5 className="mb-4 text-xl font-bold tracking-tight text-white">
                  2 / 50
                </h5>
              </div>
              <div className="mt-2 text-end" >
                <button className="font-normal border border-gray-300 px-4 py-1 rounded-full text-green-300">
                  2.00% Quota Used
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-5  max-w-sm p-6  border border-gray-700 rounded-lg shadow hover:bg-gray-700 bg-gray-800">
              <div>
                <h4 className="mb-4 text-2xl font-bold tracking-tight text-white">
                Questions (Plugin)
                </h4>
                <h5 className="mb-4 text-2xl font-bold tracking-tight text-white">
                  0 / 50
                </h5>
              </div>
              <div className="mt-2 text-end" >
                <button className="font-normal border border-gray-300 px-4 py-1 rounded-full text-green-300">
                  0.00% Quota Used
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Billing_Renewals;
