import React, { useState } from "react";
import StatusModal from "./StatusModal";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import DocuSensaLogo from "../../Images/DocuSensaLogo.png";

const UploadFilePage = () => {

  // let qdrant = "c0B_fcVRbl9O4I6RxKl-liaTyAlfh-LmqW73f4IFOCQsMO1o6ukBxw"

  // Modelsection
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  var intervalId;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  const location = useLocation();
  // console.log(location.state.file, "location ************88888i");
  
  const handleFile = (e) => {
    return new Promise((resolve, reject) => {
      let selectedFile = e.target.files[0];
      // console.log("selectedFile", e.target.files[0]);
      
      const fileType = ["application/pdf"];

    
      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (e) => {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        } else {
          // toast.warn("Invalid file type", {
          //   autoClose: 2000,
          // });
          // reject(new Error("Invalid file type"));
        }
      } else {
        reject(new Error("No file selected"));
      }
    });
  };

  const handleResponse = (res, file) => {

    // console.log("res data is ---", res)
    if (!res || !res.data) {
      console.error("Response or response data is undefined");
      return setStatus("Failed to get a valid response from the server");
    }

    if (res.data.pdf_key) {
      console.log("PDF key received:", res.data.pdf_key);
      localStorage.setItem("pdfKey", res.data.pdf_key);  // Store pdf_key regardless of status
    }

  // console.log(res.data.status, "data status is------")
    switch (res.data.status) {
      case "STARTING":
        console.log("STARTING: PDF key received:", res.data.pdf_key);
        // localStorage.setItem("pdfKey", res.data.pdf_key);
        return setStatus("Uploading Pdf ..");
      case "LOADING":
        return setStatus("Loading Pdf ...");
      case "ANALYZING":
        return setStatus("Analysing Pdf ...");
      case "SUMMARIZING":
        return setStatus("Summarizing Pdf ...");
      case "PENDING":
        return setStatus("Maybe Asynchronous task failed...");
      case "FAILED":
        return setStatus("Failed due to some issues, please try again!");
      case "Completed":
        clearInterval(intervalId);  
        setStatus("Upload successful!");
        // console.log(res.data, 'This is updated data')
        return location.pathname === "/test"
          ? navigate("/test/c", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            })
          : navigate("/chat", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            });
      default:
        console.error(`Unknown status from server ${res.data.status}`);
        throw new Error(`Unknown status from server ${res.data.status}`);
    }
  };

  // const getPdfStatus = async (file) => {
  //   const pdfKey = localStorage.getItem("pdfKey");
  //   console.log("in getpdfstatus");
  //   try {
  //     await axios
  //       .post(
  //         process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/qdrant-pdf-status/",
  //         {
  //           pdf_key: pdfKey,
  //         },
  //         {
  //           headers: {
  //             Authorization: "Token " + localStorage.getItem("token"),
  //           },
  //         }
  //       )
  //       .then((res) => handleResponse(res, file))
  //   } catch (err) {
  //     setLoading(false);
  //     console.log("err", err);
  //   }
  // };


  const upload = async (e) => {

    // Check if a file is actually selected
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      console.log("No file selected");
      return; // Exit the function if no file is selected
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    let file = null;
    handleFile(e).then((res) => {
      file = res;
    });
// console.log("formData", formData);
    try {
      // let file = await handleFile(e)
      const token = localStorage.getItem("token")
      // console.log(token, "this is token")
      setLoading(true);
      setStatus("Uploading...");
      await axios
        .post(
          // process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/qdrant-async-fast/",
          process.env.REACT_APP_BASE_URL + `/api/v1/readpdf/qdrant-upload-async/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log('API Response:', res); // Debugging the full response
          handleResponse(res, file);
          // intervalId = setInterval(() => getPdfStatus(file), 1000);
        });
    } catch (err) {
      setLoading(false);
      setStatus("Error during file upload");
      if (err.response && err.response.data && err.response.data.msg === "Unsupported file format") {
        toast.warn("Unsupported file format", { autoClose: 2000 });
      } else {
        console.error("Error during file upload:", err);
        toast.error("An error occurred during file upload", { autoClose: 2000 });
      }
    }
  };

  return (
    <div className="bg-gray-900 h-screen w-full ">
      {/* After Login & Signup UploadFilePage Front Page */}
      <div className="w-10/12 m-auto pt-16">
      <div className="flex justify-center">
          <img
            src={DocuSensaLogo}
            alt="logo"
            className="h-12 md:h-24 "
          />
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between mb-4">
          <h1 className="text-white text-[40px] font-bold md:w-1/3 w-full ">
            Upload your document
          </h1>
          <p className="text-white md:w-2/5 w-full ">
            You can initiate a conversation based on the document you've
            uploaded. To expand your capabilities, consider upgrading your
            account.
          </p>
        </div>

        <div className="border border-white"></div>
        {/* <div>
          <button className="text-white mt-4 border-2 border-white rounded-xl p-2">
            Upload Document
          </button>
        </div> */}

        <div className="mt-4">
          <div className="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-80 border-2 border-dashed rounded-lg cursor-pointer bg-gray-800  border-white  bg-clip-bg bg-gradient-to-r from-purple-700 to-red-400 hover:from-red-400 hover:to-purple-700"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  className="w-8 h-8 mb-4 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-white">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-white">
                  Supported Pdf
                </p>
              </div>
              <input
                onChange={upload}
                onClick={openModal}
                id="dropzone-file"
                type="file"
                className="hidden"
              />
            </label>
          </div>
        </div>
        {/* 
        <div className="flex justify-end">
          <button className="text-white mt-4 border-2 border-white font-bold hover:bg-purple-700 rounded-3xl p-3">
            Start Conversation
          </button>
        </div> */}

        {loading  && (
          <StatusModal
            closeModal={closeModal}
            status={status}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default UploadFilePage;
