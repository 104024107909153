import React, { useState } from "react";
import { BsDashCircleFill, BsPlusCircleFill } from "react-icons/bs";

const iconStyle = {
  fontSize: "24px",
  fontWeight: "bold",
};

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="w-10/12 m-auto">
      {items.map((item, index) => (
        <div key={index} className="mb-2">
          <button
            className="w-full bg-gray-300 py-2 px-4 flex justify-between items-center"
            onClick={() => toggleAccordion(index)}
          >
            <span className="text-left font-bold ">{item.title}</span>
            <span className="text-right">
              {activeIndex === index ? (
                <BsDashCircleFill style={iconStyle} />
              ) : (
                <BsPlusCircleFill style={iconStyle} />
              )}
            </span>
          </button>
          {activeIndex === index && (
            <div className="bg-gray-100 text-gray-600 font-semibold md:text-justify p-4">
              {item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const TermAndCondition = () => {
  const items = [
    {
      title: "Acceptance of Terms:",
      content:
        "Your use of DocuSensa implies your agreement to these terms. If you don't agree, please refrain from using DocuSensa.",
    },
    {
      title: "Using DocuSensa:",
      content:
        "Feel free to use DocuSensa for personal or non-commercial purposes only. Any illegal or unauthorized use is strictly prohibited.",
    },
    {
      title: "Uploading Documents:",
      content:
        "You can upload documents to DocuSensa for sharing with others, but refrain from uploading illegal, defamatory, obscene, or objectionable content.",
    },
    {
      title: "User Conduct:",
      content:
        "Be responsible for your actions on DocuSensa. Avoid engaging in illegal, defamatory, threatening, or abusive behavior. Also, respect the intellectual property rights of others.",
    },
    {
      title: "User Content:",
      content:
        "You maintain ownership and control of the content you import into DocuSensa. We won't claim ownership or access it unless required by law.",
    },
    {
      title: "App Permissions:",
      content:
        "We may request device permissions to enable document import and viewing. These permissions are solely for this purpose.",
    },
    {
      title: "Automatically Collected Information:",
      content:
        "When you use our app, we may collect certain information automatically, such as technical information about your device, app usage statistics, and crash reports. This information is collected in an anonymized and aggregated form and is used to improve our app's performance, user experience, and overall quality.",
    },
    {
      title: "Privacy:",
      content:
        "Your privacy is important to us. Please see our privacy policy for information on how we collect, use, and protect your personal information.",
    },
    {
      title: "Termination:",
      content:
        "We may terminate your use of DocuSensa at any time for any reason, including if we believe that you have violated these terms and conditions.",
    },
    {
      title: "Subscriptions and cancellations:",
      content:
        "Subscribers can cancel their subscriptions at any time, but no refunds will be provided for any unused portion of the subscription period.",
    },
    {
      title: "Disclaimer of Warranties:",
      content:
        "DocuSensa is provided on an 'as is' and 'as available' basis. We do not warrant that DocuSensa will be uninterrupted, error-free, or free from downtime.",
    },
    {
      title: "Limitation of Liability:",
      content:
        "To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of DocuSensa.",
    },
    {
      title: "Governing Law:",
      content:
        "These terms and conditions shall be governed by and construed in accordance with the laws of the country or state where it is used.",
    },
    {
      title: "Changes to these Terms and Conditions:",
      content:
        "We reserve the right to modify these terms and conditions at any time without prior notice. Your continued use of DocuSensa after any changes to these terms and conditions constitutes your acceptance of the revised terms and conditions.",
    },
  ];

  return (
    <div className="w-full h-full bg-gray-900 pb-8">
      <div
        className="flex flex-col justify-center  gap-2 items-center h-[200px] md:h-[400px]"
        style={{
          backgroundImage: `url("https://coverse.org.au/wp-content/uploads/2022/09/reviewing-document.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="text-black  text-[30px] md:text-[60px] font-bold">
          Term And Condition
        </h1>
        <h6 className="text-black text-[15px] md:text-[18px] font-bold">
          Welcome to DocuSensa. Please review these terms and conditions
          thoroughly, as they oversee your product usage.
        </h6>
      </div>

      <div className="mt-6">
        <Accordion items={items} />
      </div>
    </div>
  );
};

export default TermAndCondition;
