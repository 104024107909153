import React from 'react'
import Billing_Renewals from './Billing_Renewals'
import AccountPlans from './AccountPlans'
import CancelPlans from './CancelPlans'

const Setting = () => {
  return (
    <div className='md:h-screen  p-10 overflow-y-auto w-full bg-gray-900'>
        <AccountPlans />
        <Billing_Renewals />
        <CancelPlans />
    </div>
  )
}

export default Setting