import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";
import { FcGoogle } from "react-icons/fc";
import { MdAccountCircle } from "react-icons/md";
import OtpPage from "./OtpSection/OtpPage";
import axios from "axios";
import { useNavigate } from "react-router";
import GoogleSignupButton from "../../components/GoogleSignupButton";

const SignupPage = () => {
  const navigate = useNavigate();
  const [signupDetails, setSignupDetails] = useState({
    email: "",
    password: "",
  });

  // Password validation regular expressions
  const passwordRegex = {
    minLength: /(?=.{6,})/,
    hasUppercase: /(?=.*[A-Z])/,
    hasNumber: /(?=.*\d)/,
  };

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasUppercase: false,
    hasNumber: false,
  });

  // Modelsection
  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState("");

  const openModal = () => {
    setModalEmail(signupDetails.email);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // visibility Passward
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // verify signin button
  const [verified, setVerified] = useState(false);
  const loginMessage = () => {
    toast.success("Login successfully!", {
      autoClose: 2000,
    });
  };

  // const handleSignupDetailsChange = (event) => {
  //   const { name, value } = event.target;
  //   setSignupDetails((prevSignupDetails) => ({
  //     ...prevSignupDetails,
  //     [name]: value,
  //   }));
  // };

  const handleSignupDetailsChange = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      const newPassword = value;

      setPasswordValidation({
        minLength: passwordRegex.minLength.test(newPassword),
        hasUppercase: passwordRegex.hasUppercase.test(newPassword),
        hasNumber: passwordRegex.hasNumber.test(newPassword),
      });
    }

    setSignupDetails((prevSignupDetails) => ({
      ...prevSignupDetails,
      [name]: value,
    }));
  };

  console.log(process.env.REACT_APP_API_URL , 'this is url ')
  const [token, setToken] = useState("");

  const handleSignUp = async () => {
    const payload = {
      email: signupDetails.email,
      password: signupDetails.password,
    };
    console.log(payload);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/signup/",
        payload
      );
      console.log("response1111111111", response.data.status);
      
      // console.log("token", response.data.data.token);

      if (response.data.status === 400) {
        toast.warn("Email already used", {
          autoClose: 2000,
        });
      } else if (response.data.status === 200) {
        // toast.success("Signup successful!", {
        //   autoClose: 2000,
        // });
        window.localStorage.setItem("token", response.data.data.token);
        openModal();
        setToken(response.data.data.token);
      }

    } catch (error) {
      // Handle signup error
      toast.error("Signup failed. Please try again.");
      console.error("Signup error:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setVerified(true);
    }
  }, []);

  // If verified is true, navigate to "/uploadFile"
  useEffect(() => {
    if (verified) {
      navigate("/uploadFile");
    }
  }, [verified, navigate]);
  return (
    <div className="relative">
      <section className= "bg-gray-900 w-full py-2">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full  rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div className=" w-full text-white ">
                <GoogleSignupButton
                  setVerified={setVerified}
                  loginMessage={loginMessage}
                />
              </div>

              <div className="inline-flex gap-2 items-center justify-center w-full">
                <hr className="w-64 h-px my-2 bg-gray-200 border-0 " />
                <span className="absolute px-4 font-medium  -translate-x-1/2 left-1/2 text-white bg-gray-800 ">
                  or
                </span>
              </div>

              <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
                Create and account
              </h1>
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  value={signupDetails.email}
                  onChange={handleSignupDetailsChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="name@gmail.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="••••••••"
                    value={signupDetails.password}
                    onChange={handleSignupDetailsChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="text-white absolute right-4 top-1/2 transform -translate-y-1/2"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
                {/* Password validation messages */}
                {signupDetails.password && (
                  <div className="text-sm text-red-500 dark:text-red-400">
                    {!passwordValidation.minLength &&
                      "Password must be at least 6 characters long."}
                    {!passwordValidation.hasUppercase &&
                      "Password must contain at least one uppercase letter."}
                    {!passwordValidation.hasNumber &&
                      "Password must contain at least one number."}
                  </div>
                )}
              </div>

              {/* <div>
                  <label
                    for="confirm-password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirm password
                  </label>
                  <input
                    type="confirm-password"
                    name="confirm-password"
                    id="confirm-password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                  />
                </div> */}

              <button
                onClick={handleSignUp}
                disabled={
                  !passwordValidation.minLength ||
                  !passwordValidation.hasUppercase ||
                  !passwordValidation.hasNumber
                }
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Create an account
              </button>

              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    required=""
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    for="terms"
                    className="text-gray-300 font-medium"
                  >
                    I want to receive offers and product updates
                  </label>
                </div>
              </div>
              <p className="text-sm font-light text-gray-300">
                Already have an account?{" "}
                <a
                  href="/signin"
                  className="font-medium  hover:underline text-primary-500"
                >
                  Login here
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <OtpPage closeModal={closeModal} email={modalEmail} token={token} />
      )}
    </div>
  );
};

export default SignupPage;
