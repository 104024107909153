import React from "react";
import VideoFile from "./VideoFile/uploadPDFVideo.mp4";

const VideoSection = () => {
  return (
    <div className="mt-12 bg-white pb-8">
      <h1 className="md:ml-12 text-center md:text-start text-[25px] md:text-[40px] font-bold ">
        How to use DocuSensa?
      </h1>
      <h6 className="md:ml-12 text-center md:text-start text-[15px] font-medium md:text-[20px]  mb-6">
        Get the Future of document analysis, chat, and video summaries.{" "}
      </h6>

      <div className=" m-auto w-[350px] md:w-[800px] h-[200px] md:h-[450px] border-8 border-purple-500 rounded-[34px]">
        <video
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "26px",
          }}
          playsInline
          autoPlay
          muted
          loop
          controls
        >
          <source src={VideoFile} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default VideoSection;
