import React, { useEffect, useState } from "react";
import "./home.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { ProgressBar } from "react-loader-spinner";
import { TypeAnimation } from "react-type-animation";
import { toast } from "react-toastify";


const PdfUploadTopSection = () => {
  var intervalId;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [verified, setVerified] = useState(false);

  const location = useLocation();

  const invokeUpload = () => {
    if (!verified) {
      navigate("/signin");
    } else {
      document.getElementById("upload-file").click();
    }
  };

  const handleFile = (e) => {
    return new Promise((resolve, reject) => {
      let selectedFile = e.target.files[0];
      const fileType = ["application/pdf"];

      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (e) => {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        } else {
          reject(new Error("Invalid file type"));
        }
      } else {
        reject(new Error("No file selected"));
      }
    });
  };

  const handleResponse = (res, file) => {

    if (!res || !res.data) {
      console.error("Response or response data is undefined");
      return setStatus("Failed to get a valid response from the server");
    }

    if (res.data.pdf_key) {
      console.log("PDF key received:", res.data.pdf_key);
      localStorage.setItem("pdfKey", res.data.pdf_key);  // Store pdf_key regardless of status
    }

    switch (res.data.status) {
      case "STARTING":
        localStorage.setItem("pdfKey", res.data.pdf_key);
        return setStatus("Uploading Pdf ..");
      case "LOADING":
        return setStatus("Loading Pdf ...");
      case "ANALYZING":
        return setStatus("Analysing Pdf ...");
      case "SUMMARIZING":
        return setStatus("Summarizing Pdf ...");
      case "FAILED":
        return setStatus("Failed due to some issues, please try again!");
      case "Completed":
        clearInterval(intervalId);
        return location.pathname === "/test"
          ? navigate("/test/c", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            })
          : navigate("/chat", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            });
      default:
        throw new Error(`Unknown status from server ${res.data.status}`);
    }
  };

  // const getPdfStatus = async (file) => {
  //   const pdfKey = localStorage.getItem("pdfKey");
  //   console.log("here");
  //   try {
  //     await axios
  //       .post(
  //         process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/pdf-status/",
  //         {
  //           pdf_key: pdfKey,
  //         },
  //         {
  //           headers: {
  //             Authorization: "Token " + localStorage.getItem("token"),
  //           },
  //         }
  //       )
  //       .then((res) => handleResponse(res, file));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const upload = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    let file = null;
    handleFile(e).then((res) => {
      file = res;
    });
// console.log("upload pdf");

    try {
      setLoading(true);
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/qdrant-upload-async/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          handleResponse(res, file);
          // intervalId = setInterval(() => getPdfStatus(file), 1000);
        });
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.msg === "Unsupported file format") {
        toast.warn("Unsupported file format", { autoClose: 2000 });
      } else {
        console.error("Error during file upload:", err);
        toast.error("An error occurred during file upload", { autoClose: 2000 });
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setVerified(true);
    }
  }, []);
  
  return (
    <div className="home-container bg-gray-900 w-full h-full p-4 md:p-2">
      <div className="header flex justify-center h-20 mt-8  items-center text-gray-200 text-[50px] font-semibold font-mono">
        DOCU <span className="text-purple-500"> SENSA</span>
      </div>
      <div className="subheader flex justify-center text-center text-gray-200 text-[30px] md:text-[50px]  font-semibold font-sans mt-2 flex-col">
        <p>It's not just reading anymore,</p>
        <div className="flex w-full justify-center gap-4">
          <p>It's </p>
          <p className="w-[450px]  text-left">
            <TypeAnimation
              preRenderFirstString={true}
              sequence={[
                " conversation ",
                1500,
                " discovery",
                1500,
                " exchanging Ideas",
                1500,
              ]}
              wrapper="span"
              speed={50}
              style={{ display: "inlike-block", color: "rgb(168 85 247)" }}
              repeat={Infinity}
            />
          </p>
        </div>
      </div>

      <div className="sub-text font-sans flex justify-center mt-6 text-center text-gray-200  text-md">
        <span>
          Join millions of <span className="text-purple-500">student </span> ,{" "}
          <span className="text-purple-500">researchers</span> and{" "}
          <span className="text-purple-500">professionals</span>
          <br /> to instantly answer questions and understand research with AI
        </span>
      </div>

      <div className="top-section w-full flex justify-center items-center pt-6">
        <div
          
          className="upload-button-container w-[180px] h-[60px] border-2  rounded-md  border-purple-700 flex justify-center items-center cursor-pointer hover:bg-purple-100 hover:border-0 "
        >
          <button className="upload-btn">
            {loading ? (
              <div className="text-red-500">
                <ProgressBar
                  height="80"
                  width="200"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="rgb(126 34 206 )"
                  barColor="#51E5FF"
                />
              </div>
            ) : (
              <div onClick={invokeUpload} className="flex justify-around items-center gap-8">
                <FiUpload size={30} color="rgb(126 34 206)" />{" "}
                <div className="upload-text text-white">Upload PDF</div>
              </div>
            )}
          </button>

          <input
            onChange={upload}
            className="hidden"
            id="upload-file"
            type="file"
          />
        </div>
      </div>
      <div className="text-white mt-3 text-center">{status}</div>
      {/* <div className="resents w-full h-[300px]"></div> */}
    </div>
  );
};

export default PdfUploadTopSection;