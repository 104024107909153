import React from "react";
import CancelPlanModel from "./CancelPlanModel";

const CancelPlans = () => {
  return (
    <div>
      <h1 className="text-xl font-bold text-white mt-6">Cancel Subscription</h1>
      <h3 className="text-gray-400">
        This is the field that you can Cancel your plan.
      </h3>
      <div className="border border-white mt-4"></div>

      <div className="mt-4">{<CancelPlanModel />}</div>
    </div>
  );
};

export default CancelPlans;
