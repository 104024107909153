import { googleLogin } from "../services/login";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";

function GoogleSignupButton(props) {
  const hostname = window.location.hostname;

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  /*global google*/
  let client;
  if (typeof google != "undefined") {
    console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID); 
    console.log('It is in if condition', process.env.REACT_APP_GOOGLE_CLIENT_ID)
    client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      callback: async (response) => {
        await googleLogin(response.code, hostname).then((res) => {
          props.setVerified(true);
          props.loginMessage();
        });
      },
    });
    console.log(client,'success')
  } else {
    console.log('It is else if condition')
    async function wait_for_1_sec() {
      await delay(1000);
      client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",

        callback: async (response) => {
          await googleLogin(response.code, hostname).then((res) => {
            props.setVerified(true);
            props.loginMessage();
          });
        },
      });
    }
    wait_for_1_sec();
  }

  return (
    <button
      id="google-login-btn"
      onClick={() => client.requestCode()}
      className=" border-white border-2 flex justify-between h-[35px] w-full items-center bg-blue-600 rounded-md"
    >
      <div className="bg-white h-full w-16 flex justify-center items-center">
        <FcGoogle />
      </div>
      <div className="text-center text-white text-sm w-full flex justify-center font-semibold items-center">
        <div className=" ">Sign up with Google</div>
      </div>
    </button>
  );
}
export default GoogleSignupButton;
