import BlogImage1 from "../../Images/BlogImage1.jpg";
const blogData = [
  {
    id: 1,
    image: BlogImage1,
    title: "Mastering DocuSensa: Pro Tips for Maximum Efficiency",
    content:
      "Unlock the full potential of DocuSensa by mastering these 6 professional tips. Enhance your productivity and make the most out of this robust tool.",
    author: "Shobhit Gupta",
    date: "10/10/2023",
    avatar: "https://img.freepik.com/premium-vector/3d-avatar_665280-46961.jpg",
    additionalContent: [
      {
        title: "All you need to do is :",
        content: [
          "1. Sign in to your DocuSensa account.",
          "2. Locate the target document within your uploaded documents. If the document hasn't been uploaded yet, consult this article for guidance on the document upload process.",
          "3. Once the document is open, then you can ask your Question to DocuSensa input box, and you'll receive your answer.",
        ],
      },
      {
        title:
          "By now, you might be contemplating the extensive possibilities offered by this feature",
        content: [
          "It's particularly beneficial if you fit into one of the following categories:",
          "1. A student working on a paper or preparing for an important test.",
          "2. A professional dealing with heavy reading or lengthy documents, be it in the legal, medical, or financial domain.",
          "3. A content creator or writer in search of a wellspring of inspiration and fresh ideas.",
        ],
      },
    ],
  },
  {
    id: 2,
    image: BlogImage1,
    title: "Optimizing DocuSensa: A Guide to Streamlined Document Automation",
    content:
      "Take advantage of DocuSensa's document automation capabilities with this comprehensive guide. Discover how to streamline your tasks and automate processes with ease.",
    author: "Emily Davis",
    date: "10/10/2023",
    avatar: "https://img.freepik.com/premium-vector/3d-avatar_665280-46961.jpg",
    "additionalContent": [
        {
          "title": "Getting Started with DocuSensa",
          "content": [
            "1. Sign in to your DocuSensa account.",
            "2. Locate the target document within your uploaded documents. If the document hasn't been uploaded yet, consult this article for guidance on the document upload process.",
            "3. Once the document is open, you can start automating your tasks using DocuSensa's features.",
          ]
        },
        {
          "title": "Benefits of Document Automation",
          "content": [
            "Document automation offers various benefits, including:",
            "1. Increased productivity by reducing manual tasks.",
            "2. Improved accuracy and consistency in document generation.",
            "3. Time and cost savings by automating repetitive processes.",
          ]
        },
        {
          "title": "Use Cases for DocuSensa Automation",
          "content": [
            "DocuSensa's document automation can be valuable for different scenarios, such as:",
            "1. Legal professionals creating contracts and legal documents.",
            "2. Sales teams generating sales proposals and contracts.",
            "3. HR departments automating employee onboarding processes.",
          ]
        }
    ]
  },
];

export default blogData;
