import React from "react";
import SuccessArrow from "../../Images/successArrow.svg";
import { Link } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <div className="h-full md:h-screen flex items-center justify-center">
      <div className="flex flex-col text-center w-[500px]">
        <img className="h-20" src={SuccessArrow} alt="" />
        <h1 className="mt-4 text-white text-3xl font-bold ">Payment Done !</h1>
        <h3 className="mt-4 text-white text-xl font-semibold ">
          Thank you for completing your secure online payment. Have a great day!
        </h3>

        <Link className="flex items-center justify-center " to="/">
          <button
            type="button"
            className="mt-6 flex items-center justify-center  px-5 py-2 text-md text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 font-bold hover:bg-purple-500 hover:text-white"
          >
            <svg
              className="w-5 h-5 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
            <span>Go back to Home</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
