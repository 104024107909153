import React from "react";
import PrivacyBackImage from "../../Images/privacy-policy-cover.jpg";

const PrivacyPage = () => {
  return (
    <div className="w-full h-full bg-gray-900 pb-8">
      <div
        className="flex flex-col justify-center  gap-2 items-center h-[200px] md:h-[400px]"
        style={{
          backgroundImage: `url(${PrivacyBackImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className=" text-[30px] text-white md:text-[60px] font-bold">
          Privacy Policy
        </h1>
        <h6 className=" text-[15px] text-white md:text-[18px]  font-bold">
          Safeguarding Your Privacy: Our Dedication and Promise
        </h6>
      </div>

      <div className="w-10/12 m-auto mt-6 ">
        <p className="text-white leading-7 text-justify ">
          At DocuSensa, we take your privacy seriously. We're committed to
          providing a platform where you can work with your documents while
          keeping your personal information safe. Our Privacy Policy explains
          what data we collect, how we collect it, why we collect it, and how we
          ensure it's handled with care.
        </p>

        <div className="mt-6">
          <h1 className="text-white text-[32px] font-bold">
            The Data We Gather and Its Purpose
          </h1>
          <p className="text-white mt-6">
            To ensure an exceptional user experience on DocuSensa, we gather
            specific categories of information from our users:
          </p>

          <p className="text-white mt-2 leading-7 text-justify">
            <span className="font-bold">1. Account Details: </span> When you
            register an account with us, we compile fundamental particulars like
            your name, email address, and password. This data is utilized for
            user identification and to furnish tailored services.
          </p>
          <p className="text-white mt-2 leading-7 text-justify">
            <span className="font-bold">2. Uploaded Files: </span> When you
            submit a document on our platform, we temporarily save the file to
            facilitate our interactive document services. We refrain from any
            other utilization of your documents.
          </p>
          <p className="text-white mt-2 leading-7 text-justify">
            <span className="font-bold">3. User Engagements: </span>We amass
            information regarding how you engage with our offerings. This
            encompasses details such as the timing, regularity, and duration of
            your interactions. This aids us in comprehending usage trends,
            refining our services, and delivering a more personalized user
            journey.
          </p>
        </div>

        <div className="mt-6">
          <h1 className="text-white text-[32px] font-bold">Data Control</h1>
          <p className="text-white mt-6 leading-7 text-justify">
            At DocuSensa, we hold your ability to manage your data in high
            regard. You have the option to remove your uploaded document files
            from our service whenever you wish, completely eliminating them from
            our servers. In the interest of preserving the privacy of our free
            users, we go a step further by automatically purging uploaded files
            a few hours after their use, ensuring no residual data lingers on
            our servers longer than necessary.
          </p>
        </div>

        <div className="mt-6">
          <h1 className="text-white text-[32px] font-bold">Data Protection</h1>
          <p className="text-white mt-6 leading-7 text-justify">
            We recognize the sensitivity of your personal information and employ
            stringent security protocols to shield it. Your data is securely
            stored on our servers, and access is strictly limited to a select
            group of authorized personnel bound by confidentiality obligations.
            Additionally, we conduct periodic reviews and updates of our
            security measures.
          </p>
        </div>

        <div className="mt-6">
          <h1 className="text-white text-[32px] font-bold">
            External Services
          </h1>
          <p className="text-white mt-6 leading-7 text-justify">
            It's important to note that our Privacy Policy does not extend to
            services offered by other entities or individuals, including
            third-party services used in conjunction with DocuSensa. We
            recommend that you review the privacy policies of any third-party
            services you might utilize.
          </p>
        </div>

        <div className="mt-6">
          <h1 className="text-white text-[32px] font-bold">
            Privacy Policy Updates
          </h1>
          <p className="text-white mt-6 leading-7 text-justify">
            We retain the right to modify or amend this Privacy Policy at any
            time to align with changes in our practices, services, or legal
            requirements. We will apprise you of significant alterations and
            encourage you to periodically review this policy to stay informed.
          </p>
          <p className="text-white mt-6 leading-7 text-justify">
            Your continued use of DocuSensa services following any changes to
            this Privacy Policy indicates your acceptance of the practices
            outlined in the updated policy.
          </p>
        </div>

        <div className="mt-6">
          <h1 className="text-white text-[32px] font-bold">Get in Touch</h1>
          <p className="text-white mt-6 leading-7 text-justify">
            If you have inquiries or concerns about our data protection
            practices or this Privacy Policy, please feel free to reach out to
            us. We highly value your feedback and eagerly await your
            communication.
          </p>
          <p className="text-white mt-6 leading-7 text-justify">
            By using DocuSensa, you acknowledge that you have read,
            comprehended, and consented to the practices delineated in this
            Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
