import React from "react";
import ChatSidebar from "./ChatSidebar";
import Menu from "./Menu";

const Layout = ({ children }) => {
  return (
    <div className="chat-container flex flex-row justify-evenly h-full md:h-screen w-[100%]">
      <div>
        {/* <ChatSidebar /> */}
        <Menu />
      </div>
      <div className="ml-14  w-[100%]">{children}</div>
    </div>
  );
};

export default Layout;
