import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { IoMdContacts } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import "./pricing.css";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const navigate = useNavigate();
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

  const cardInfo = [
    {
      title: "Starter",
      planDes: "Free Forever",
      subHeader:
        "Try and test it with 0$ the price for quality, accurate, precise responses",
      monthlyPrice: 0,
      yearlyPrice: 0,
      purchaseText: "Get Started",
      points: [
        "Document Upload Limit - 1",
        "Daily questions limit - 5 messages/day",
        "Chat with all PDFs - Yes",
        "Max file size - 5MB",
        "Excel Upload - Yes",
        "Chrome extension - No",
        "Customer support - Email",
      ],
    },
    {
      title: "Pro",
      planDes: !isMonthly ? "Billed monthly" : "Billed Annually",
      subHeader: "Consult us for the needs, custom price, as per requirements",
      monthlyPrice: 9.99,
      yearlyPrice: 99,
      purchaseText: "Purchase Pro",
      icon: null, // You can add an icon if needed
      points: [
        "Document Upload Limit - Unlimited",
        "Daily questions limit - Unlimited",
        "Chat with all PDFs - Unlimited",
        `Max file size - ${!isMonthly ? "15 MB" : "50 MB"}`,
        "Excel Upload - Yes",
        "Video Scripts - Coming Soon",
        "Chrome extension - Coming Soon",
        "Google Drive Integration - coming soon",
        "Drop Box Integration - Coming Soon",
        "HubSpot Integration - coming soon",
        "GPT-4 (available as a paid add-on) - Yes",
        "Ability to Choose LLM Models - coming soon",
        "Business API access - coming soon",
        "Invite team members - coming soon",
        "Customer support - Premium Support",
      ],
    },
  ];

  const handlePaymentFrequencyChange = (isMonthly) => {
    setIsMonthly(isMonthly);
  };

  const handleButtonClick = (route) => {
    navigate(route);
  };

  const handlePayment = async () => {
    const stripe = await stripePromise;
    const paymentFrequency = !isMonthly ? "Monthly" : "Yearly";
    // Call your backend to create a Checkout Session
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/api/v1/accounts/create_checkout/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          subscription_plan: "Paid",
          payment_frequency: paymentFrequency,
        }),
      }
    );

    const session = await response.json();

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.session_id,
    });

    if (result.error) {
      console.error(result.error.message);
      // Handle errors if needed
    }
  };
  return (
    <div className="w-full h-full bg-gray-900 pb-6 ">
      <div className="top-section w-full flex flex-col justify-center">
        <div className="header flex justify-center mt-4 items-center">
          <h1 className=" text-gray-200 text-[40px] font-bold">PRICING PLAN</h1>
        </div>
        <p className="text-white text-[30px] font-semibold w-full text-center mt-4">
          Purchase Premium for <br />{" "}
          <span className="text-purple-500">Better</span> ,{" "}
          <span className="text-purple-500">Faster</span> ,
          <span className="text-purple-500">Quality</span> Responses
        </p>
      </div>

      <div className="flex justify-center items-center mt-6">
        <span className="mr-3 text-md font-medium text-gray-300">Monthly</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={isMonthly}
            onChange={() => handlePaymentFrequencyChange(!isMonthly)}
            className="sr-only peer"
          />
          <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4  peer-focus:ring-purple-800 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
          <span className="ml-3 text-md font-medium text-gray-300">Yearly</span>
        </label>
      </div>
      <div className="mid-section w-full h-full py-5">
        <div className="price-card-container w-full h-full flex md:flex-row flex-col items-center justify-center gap-20 pt-5">
          {cardInfo.map((card, index) => (
            <div
              key={index}
              className="card border-2 w-[350px] md:w-[500px] pb-6 h-auto bg-white rounded-xl"
            >
              <div className="card-header w-full text-center text-5xl mt-8 font-bold font-sans">
                {card.title}
              </div>
              <div className="w-full text-center mt-2 font-[500] text-lg px-5 font-style: italic text-purple-700">
                {card.planDes}
              </div>
              <div className="card-sub-header w-full text-center mt-4 font-[500] text-lg px-5">
                {card.subHeader}
              </div>
              <div className="card-price w-full text-center mt-6 text-[65px] font-bold">
                {isMonthly ? `$${card.yearlyPrice}` : `$${card.monthlyPrice}`}
                <sub className="text-[20px] text-gray-500 font-medium ">
                  {isMonthly ? "/year " : "/month"}
                </sub>
              </div>
              {/* <div className="purchase-container w-full flex justify-center mt-5">
                <button className="purchase-btn rounded-3xl bg-gray-800 w-[300px] md:w-[350px] py-3 text-2xl font-bold text-white flex justify-around items-center">
                  <div>{card.purchaseText}</div> <BsArrowRight size={25} />
                </button>
              </div> */}
              <div className="purchase-container w-full flex justify-center mt-5">
                {index === 1 && ( // Only assign handlePurchaseClick to index 1 (Pro Plan)
                  <button
                    onClick={handlePayment}
                    className="purchase-btn rounded-3xl bg-gray-800 w-[300px] md:w-[350px] py-3 text-2xl font-bold text-white flex justify-around items-center"
                  >
                    <div>{card.purchaseText}</div> <BsArrowRight size={25} />
                  </button>
                )}
                {index === 0 && ( // Render Get Started button for index 0 (Starter Plan)
                  <button
                    onClick={() => navigate("/uploadFile")}
                    className="get-started-btn rounded-3xl bg-gray-800 w-[300px] md:w-[350px] py-3 text-2xl font-bold text-white flex justify-around items-center"
                  >
                    <div>{card.purchaseText}</div> <BsArrowRight size={25} />
                  </button>
                )}
              </div>
              <div className="card-points-container w-full flex flex-col gap-3 justify-center pt-8">
                {card.points.map((point, i) => (
                  <div
                    key={i}
                    className="w-full px-8 flex gap-2 font-[500] items-center"
                  >
                    <div className="font-bold">
                      <TiTick color="teal" size={25} />
                    </div>
                    {point}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
