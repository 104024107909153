import React from "react";

const WorkFlow = () => {
  return (
    <div className="bg-gray-900 mt-10">
      <h1 className="text-white md:ml-12 text-center md:text-start text-[25px] md:text-[40px] font-bold mb-6">
        How to DocuSensa work
      </h1>

      <div className=" mx-16 flex flex-col lg:flex-row gap-8 lg:grid-cols-2 justify-center">
        <div className="block max-w-sm p-6 border border-gray-700 rounded-lg shadow hover:bg-gray-700 bg-gray-800">
          <h4 className="mb-2 text-3xl font-bold tracking-tight text-white">
            Step 1
          </h4>
          <h5 className="mb-2 text-xl font-bold tracking-tight text-white">
            Upload PDF
          </h5>
          <p className="font-normal text-gray-400">
            Upload the PDF Document you want to analyze
          </p>
        </div>

        <div className="block max-w-sm p-6 border border-gray-700 rounded-lg shadow hover:bg-gray-700 bg-gray-800">
          <h4 className="mb-2 text-3xl font-bold tracking-tight text-white">
            Step 2
          </h4>
          <h5 className="mb-2 text-xl font-bold tracking-tight text-white">
            Start to Ask Questions
          </h5>
          <p className="font-normal text-gray-400">
            Start Asking questions to the interactive chat bot about the PDf
          </p>
        </div>

        {/* <div className="block max-w-sm p-6 border border-gray-700 rounded-lg shadow hover:bg-gray-700 bg-gray-800">
          <h4 className="mb-2 text-3xl font-bold tracking-tight text-white">
            Step 3
          </h4>
          <h5 className="mb-2 text-xl font-bold tracking-tight text-white">
            Sources Included
          </h5>
          <p className="font-normal text-gray-400">
            Every Response is backed by sources extracted from the uploaded
            documents
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default WorkFlow;
