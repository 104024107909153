import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Homepage from "../pages/home/Homepage";
import Chat from "../pages/chat/Chat";
import Pricing from "../pages/pricing/Pricing";
import PrivacyPage from "../pages/Privacy/PrivacyPage";
import TermAndCondition from "../pages/Term&Condition/TermAndCondition";
import Signin from "../pages/Login&Signup/Signin";
import BlogPage from "../pages/Blog/BlogPage";
import BlogDetails from "../pages/Blog/BlogDetails";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import Footer from "../pages/Footer/Footer";
import SignupPage from "../pages/Login&Signup/SignupPage";
import UploadFilePage from "../pages/UploadFile/UploadFilePage";
import ChatTest from "../pages/chat/ChatTest";
import Menu from "../pages/chat/Menu";
import Layout from "../pages/chat/Layout";
import Setting from "../pages/chat/setting/Setting";
import PaymentSuccess from "../pages/pricing/PaymentSuccess";

import PaymentFailure from "../pages/pricing/PaymentFailure";
import UploadExcel from "../pages/chat/Excel_chat/UploadExcel";
import UploadYoutubeUrl from "../pages/chat/Youtube/UploadYoutubeUrl";
import YoutubeSummary from "../pages/chat/Youtube/YoutubeSummary";
import ExcelChat from "../pages/chat/Excel_chat/ExcelChat";

const MainRoutes = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isFooterVisible =
    ["/", "/pricing", "/privacy", "/term&condition", "/blog"].includes(
      pathname
    ) || pathname.startsWith("/blog/");

  const token = window.localStorage.getItem("token");
  console.log("user token", token);
  

  // const PrivateRoute = ({ children }) => {
  //   return token ? children : <Navigate to="/signup" replace />;
  // };

  const PrivateRoute = ({ children }) => {
    // const token = window.localStorage.getItem("token");
  // console.log("Token in private route", token);
  
    return token ? children : <Navigate to="/signup" replace />;
  };
  

  // const PrivateRoute = ({ children }) => {
  //   if (!token) {
  //     console.log("No token found, redirecting to signup.");
  //     return <Navigate to="/signup" replace />;
  //   }
  //   return children;
  // };
  

  useEffect(() => {
    // Check if the user is already logged in and then redirect to "/uploadFile"
    if (token) {
      navigate("/uploadFile");
    }
  }, [token]);

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/term&condition" element={<TermAndCondition />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/payment-success/" element={<PaymentSuccess />} />
        <Route path="/payment-failure/" element={<PaymentFailure />} />

        <Route
          path="/setting"
          element={
            <PrivateRoute>
              <Layout>
                <Setting />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat/:id"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route
          path="/uploadFile"
          element={
            <PrivateRoute>
              <Layout>
                <UploadFilePage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/UploadYoutubeUrl"
          element={
            <PrivateRoute>
              <Layout>
                <UploadYoutubeUrl />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/youtube-summary"
          element={
            <PrivateRoute>
              <Layout>
                <YoutubeSummary />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/upload-excel"
          element={
            <PrivateRoute>
              <Layout>
                <UploadExcel />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/excel-chat"
          element={
            <PrivateRoute>
                <ExcelChat />
            </PrivateRoute>
          }
        />

        <Route
          path="/excel-chat/:id"
          element={
            <PrivateRoute>
                <ExcelChat />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/test"
          element={
            <Layout>
              <Homepage />
            </Layout>
          }
        />
        <Route
          path="/test/c"
          element={
            <PrivateRoute>
              <Layout>
                <ChatTest />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {isFooterVisible && <Footer />}
    </React.Fragment>
  );
};

export default MainRoutes;
