import axios from "axios";

export const googleLogin = async (code, hostname) => {
	console.log(code, "code")
	let res = await axios.post(
			process.env.REACT_APP_BASE_URL +
				"/api/v1/accounts/google/auth/",
			{
				code: code,
			}
		);
	window.localStorage.setItem("token", res.data.key);
	const config = {
		headers: {
			Authorization: `Token ${window.localStorage.getItem("token")}`,
		},
	};
	let resp = await axios.get(
		process.env.REACT_APP_BASE_URL + "/api/v1/accounts/user/",
		config
	);
	return resp;
};