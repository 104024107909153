import React, { useState } from 'react'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import PdfViewer from '../../components/PdfViewer';
import ViewPdf from '../../components/ViewPdf';
import { IoSendSharp } from "react-icons/io5";
import { Navigate } from 'react-router-dom';


const ChatTest = () => {
    const location = useLocation()
    const [messageData, setMessageData] = useState([])
    const [inputMessage, setInputMessage] = useState('');

    const [model, setModel] = useState("gpt-3.5-turbo");
    const [k, setK] = useState(1);

    const logout = () => {
        localStorage.clear();
        window.location.reload()
    }

    const applySettings = async () => {
        console.log(model, "model", k, "k")
        
        const config = {
            headers: {
                Authorization: `Token ${window.localStorage.getItem("token")}`,
            },
        };
        let res = await axios.post(
            process.env.REACT_APP_BASE_URL +
                "/api/v1/readpdf/change-settings/",
            {
                model: model,
                k:k
            },
            config
        );
        
        return res;
    };


    const chatResponse = async (data, client_message) => {
        const pdf_key = localStorage.getItem("pdfKey");
        const token = localStorage.getItem("token");

        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/qdrant-chat/", {
                client_message: client_message,
                pdf_key: pdf_key

            }, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            }).then(res => setMessageData([...data, { id: data.length, msg: res.data.msg, client: false }]))

        } catch (err) {
            console.log(err)
        }

    }

    const handleInput = (something = null) => {
        console.log(messageData, "handle")
        console.log(something, "something")
        const newMessageData = [...messageData, { id: messageData.length, msg: something ? something : inputMessage, client: true }]
        setMessageData(newMessageData)
        chatResponse(newMessageData, something ? something : inputMessage);
        setInputMessage("")

    }

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleInput();
        }

    }



    return (
        <div className='chat-container flex flex-row justify-evenly h-screen w-full' >
            <div className="sidebar w-2/3  h-full bg-gray-700 text-white text-center flex flex-col justify-between">
                <div className="plus-icon-container w-full p-2 ">
                    <div>
                        <div className='text-lg my-3' >
                            Model
                        </div>
                        <div className='mb-1' >
                            GPT 3.5 family
                        </div>
                        <hr />
                        <div className='flex justify-between items-center gap-2 px-3 mt-2' >
                            <div>
                                gpt-3.5-turbo
                            </div>
                            <input type="radio" name="model" id="gpt-3.5-turbo" onClick={() => setModel("gpt-3.5-turbo")} className='mt-1' defaultChecked/>
                        </div>
                        <div className='flex justify-between items-center gap-2 px-3 mt-2' >
                            <div>
                                text-davinci-003
                            </div>
                            <input type="radio" name="model" id="text-davinci-003" onClick={() => setModel("text-davinci-003")} className='mt-1' />
                        </div>
                        <div className='flex justify-between items-center gap-2 px-3 mt-2' >
                            <div>
                                text-davinci-002
                            </div>
                            <input type="radio" name="model" id="text-davinci-002" onClick={() => setModel("text-davinci-002")} className='mt-1' />
                        </div>
                        <div className='mt-5 mb-1' >
                            GPT 3 family
                        </div>
                        <hr />
                        <div className='flex justify-between items-center gap-2 px-3' >
                            <div>
                                text-ada-001
                            </div>
                            <input type="radio" name="model" id="text-ada-001" className='mt-1' onClick={() => setModel("text-ada-001")} />
                        </div>
                        <div className='flex justify-between items-center gap-2 px-3' >
                            <div>
                                text-babbage-001
                            </div>
                            <input type="radio" name="model" id="text-babbage-001" className='mt-1' onClick={() => setModel("text-babbage-001")} />
                        </div>
                        <div className='flex justify-between items-center gap-2 px-3' >
                            <div>
                                text-curie-001
                            </div>
                            <input type="radio" name="model" id="text-curie-001" className='mt-1' onClick={() => setModel("text-curie-001")} />
                        </div>
                    </div>
                </div>

                

                <div className="sidebar-bottom-section p-2 flex flex-col gap-5">
                <div  >
                    <div>
                        no of docs
                        <span className='text-sm' >
                            {" "}( n times the cost but more accuracy)
                        </span>
                    </div>
                    <input type="range" name="" id="k" min={1} max={5} defaultValue={1} onChange={(e) => setK(e.target.value)} /> {k}
                </div>
                    <button onClick={applySettings} className='border-white border-2 rounded-md h-10 w-full hover:bg-blue-500 cursor-pointer' >
                        Apply Settings
                    </button>
                </div>
            </div>
            <div className="data-display border-x-2 w-full  h-full text-center">
                <ViewPdf file={location.state.file} />
            </div>
            <div className="chatbot w-full  h-full text-center flex flex-col">
                <div className='summary-container w-full  p-2' >
                    <div className="summary text-sm py-2 px-4 font-sans text-left bg-gray-600 text-gray-200 rounded-lg">
                        {location.state.summary}
                        <div className='mt-5' >

                            
                        </div>
                    </div>

                </div>
                <div className="chat-container  h-full flex flex-col justify-between overflow-y-auto">
                    <div className="chats-container  flex-grow flex flex-col gap-2 p-3">
                        {messageData.map(ele => ele.client ? <div className='w-full   flex justify-end' >
                            <span className='px-4 py-2 bg-blue-500 rounded-xl text-white text-sm ' id={ele.id} >
                                {ele.msg}
                            </span>
                        </div> : <div className='w-full   flex justify-start' >
                            <span className='px-4 py-2  rounded-xl border-gray-300 bg-gray-600 text-gray-200 border-[1px] text-sm max-w-[85%]' id={ele.id} >
                                {ele.msg}
                            </span>
                        </div>)}
                    </div>

                </div>
                <div className="chat-input-box-container flex  p-5">
                    <input onKeyPress={handleInputKeyPress} placeholder='Enter your query' value={inputMessage} onInput={(e) => setInputMessage(e.target.value)} type="text" className='w-full border-2 self-end border-blue-500 rounded-md py-1 px-3 ' />
                    <button onClick={handleInput} className='border-2 bg-blue-500 w-10 rounded-md  h-9 flex justify-center items-center' >
                        <IoSendSharp color='white' />
                    </button>

                </div>
            </div>
        </div>
    )
}

export default ChatTest;