import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiConversation } from "react-icons/bi";
import { MdPictureAsPdf } from "react-icons/md";
import { FaFileExcel } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const PdfHistoryDrawer = () => {
  const location = useLocation();
  // console.log(location.state.file, "location&&&&&&&&&&&&&&&&&&&7")
  const [isOpen, setIsOpen] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const navigate = useNavigate();
  // console.log("pdfData", pdfData);
  // console.log("token", localStorage.getItem("token"));
  // console.log(location, "history pdf");
  
  const openDrawer = () => {
    setIsOpen(true);
  };  

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const getExcelData = async () =>{
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/excel/excels/",
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json"
          },
        }
      );
      // console.log("Excel response", response);
      setExcelData(response.data.reverse());

      // console.log("pdfdata1", response.data);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  }

  // const getSummary = async (key, type) => {
  //   if (!key || !type) return;
  
  //   try {
  //     let res;
  //     if (type === "pdf") {
  //       res = await axios.get(
  //         process.env.REACT_APP_BASE_URL + `/api/v1/readpdf/chats/?pdfKey=${key}`,
  //         {
  //           headers: {
  //             Authorization: "Token " + localStorage.getItem("token"),
  //           },
  //         }
  //       );
  //     } else if (type === "excel") {
  //       res = await axios.get(
  //         process.env.REACT_APP_BASE_URL + `/api/v1/excel/excel-chats/?excelKey=${key}`,
  //         {
  //           headers: {
  //             Authorization: "Token " + localStorage.getItem("token"),
  //           },
  //         }
  //       );
  //     }
  
  //     return res.data.summary;
  //   } catch (error) {
  //     console.error("Error fetching summary:", error);
  //     return "Error generating summary.";
  //   }
  // };
  
         
 

  const getPdfData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/pdfs/",
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json"
          },
        }
      );
      // console.log("pdf response", response);
      setPdfData(response.data.reverse());

      // console.log("pdfdata1", response.data);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const getSummary = async (file_key, type) => {
    if (!file_key || !type) return;
    console.log("llllllll",file_key)
    try {
      let response;
      if (type === "pdf") {
        response = await axios.get(
          process.env.REACT_APP_BASE_URL + `/api/v1/readpdf/chats/?pdfKey=${file_key}`,
          {
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
      } else if (type === "excel") {
        console.log("fffffffffffffffffffffffffffffffffff")
        try{
          response = await axios.get(
            process.env.REACT_APP_BASE_URL + `/api/v1/excel/excel-chats/?excelkey=${file_key}`,
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
        }catch(err){
          console.log("Excel api errorrrrrrrr",  process.env.REACT_APP_BASE_URL + `/api/v1/excel/excel-chats/?excelkey=${file_key}` , err)
        }
       
      }
      console.log('Fetched summary for Excel:', response.data.summary);
      return response.data.summary;
    } catch (error) {
      console.error("Error fetching summary:", error);
      return "Error generating summary.";
    }
  };


  const handleFileSelection = async (file_key, file, id, type) => {
    if (!file) {
      console.error("File is missing");
      alert("File is missing");
      return;
    }
    // Store file keys in localStorage
    if (type === "pdf") {
      localStorage.setItem("pdfKey", file_key);
    } else if (type === "excel") {
      localStorage.setItem("excelKey", file_key);  // Ensure excelKey is stored here
    }
  
    // Get the correct summary based on the type
    const summary = await getSummary(file_key, type);
  
    const res = {
      summary: summary || "Summary not available",
      examples: [
        { question: "Summarize this Document." },
        { question: "What is the main message in the document?" },
      ],
    };
  
    // Navigate to the appropriate route based on the file type
  //   navigate(`/chat/${id}`, {
  //         state: {
  //           file: file,
  //           summary: res.summary,
  //           questions: res.examples,
  //         },
  // })
    if (type === "pdf") {
      navigate(`/chat/${id}`, {
        state: {
          file: file,
          summary: res.summary,
          questions: res.examples,
        },
      });
    } else if (type === "excel") {
      navigate(`/excel-chat/${id}`, {
        state: {
          excelfile: file,  // Pass the file
          summary: res.summary,  // Ensure this is not undefined
          questions: res.examples,
        },
      });
    }
  };
  

  useEffect(() => {
    getPdfData();
    getExcelData(); 
  }, []);

  // console.log("pdfData", pdfData);
  
  
  return (
    <div>
      {/* Show drawer button */}
      <div className="text-center">
        <button type="button" onClick={openDrawer}>
          <BiConversation />
        </button>
      </div>

      {/* Drawer component */}
      {isOpen && (
        <div
          id="drawer-example"
          className="fixed top-0 left-0 z-40 h-full w-72 md:h-screen p-4 overflow-y-auto bg-gray-800 "
          tabIndex="-1"
          aria-labelledby="drawer-label"
        >
          <h5
            id="drawer-label"
            className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
          >
            {/* Close button */}
            <button
              type="button"
              onClick={closeDrawer}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
          </h5>

          <h1 className="text-white text-[30px] font-bold text-center ">
            Conversation
          </h1>
          <div className="border border-white mt-2"></div>
          <div className="mt-4 text-center">
            <Link to="/uploadFile">
              <button className="bg-teal-700 p-2 rounded-lg">
                New Conversation
              </button>
            </Link>
          </div>

          {/* PDF List */}
          
          <h2 className="text-white text-[20px] font-bold mt-4">PDF Files</h2>
          <div>
  {pdfData.map((item, index) => (
    <div
      key={index}
      className="flex flex-row items-center gap-4 mt-4 justify-start "
    >
      <MdPictureAsPdf color="white" />
      <h5
        onClick={() => handleFileSelection(item.key, item.pdf_file, item.id, "pdf")} // Pass type 'pdf'
        className="font-bold text-[16px] text-white cursor-pointer truncate w-48"
      >
        <div className="overflow-hidden">
        {item.title ? item.title.replace(/"/g, "") : "Untitled"}
        </div>
      </h5>
    </div>
  ))}
</div>

            {/* Excel List */}
            <h2 className="text-white text-[20px] font-bold mt-8">Excel Files</h2>
            <div>
  {excelData.map((item, index) => (
    <div
      key={index}
      className="flex flex-row items-center gap-4 mt-4 justify-start "
    >
      <FaFileExcel color="white" />
      <h5
        onClick={() => handleFileSelection(item.key, item.file, item.id, "excel")} // Pass type 'excel'
        className="font-bold text-[16px] text-white cursor-pointer truncate w-48"
      >
        <div className="overflow-hidden">
        {item.title ? item.title.replace(/"/g, "") : "Untitled"}
        </div>
      </h5>
    </div>
  ))}
</div>

        </div>
      )}
    </div>
  );
};

export default PdfHistoryDrawer;
