import axios from "axios";
import React, { useRef, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OtpPage = ({ closeModal, email, token }) => {
  const [otp, setOtp] = useState("");
  const inputs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleInput = (e, index) => {
    const input = e.target;
    if (index < inputs.length - 1 && input.value !== "") {
      inputs[index + 1].current.focus();
    }
    const newOtp = otp.slice(0, index) + input.value + otp.slice(index + 1);
    setOtp(newOtp);
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      inputs[index - 1].current.focus();
      const newOtp = otp.slice(0, index - 1) + otp.slice(index);
      setOtp(newOtp);
    }
  };

  const navigate = useNavigate();
  const handleVerify = () => {
    const data = {
      email: email,
      otp: otp,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "/api/v1/accounts/verify/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.message === "Account verified") {
          console.log("Account verified");
          toast.success("Account verified", {
            autoClose: 1000,
          });
          navigate("/uploadFile");
          toast.success("Signup successful!", {
            autoClose: 2000,
          });
        } else if (response.data.data == "Wrong OTP") {
          toast.warn("Wrong OTP", {
            autoClose: 2000,
          });
        } else if (response.data.data == "Invalid email") {
          toast.warn("Invalid email", {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.error("Error while verifying OTP:", error);
      });
  };

  const handleResendOTP = () => {
    // Prepare the request headers with the Authorization token
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };
    // Send a POST request
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/resend-otp/",
        null,
        { headers }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.message === "New OTP sent to your email address.") {
          toast.success("New OTP sent to your email address.", {
            autoClose: 2000,
          });
        } else {
          // Handle request failure
          console.error("Resend OTP failed");
        }
      })
      .catch((error) => {
        // Handle request error
        console.error("Error while resending OTP:", error);
      });
  };
  return (
    <div>
      <div className="bg-gray-900 absolute inset-0 flex items-center justify-center z-50">
        <div className="bg-white h-auto p-4 text-center">
          <h1 className="text-2xl font-bold">OTP Verification</h1>
          <div className="flex flex-col mt-4">
            <span>Enter the OTP you received at</span>
            <span className="font-bold">{email}</span>
          </div>

          <div className="flex flex-row justify-center text-center px-2 mt-5">
            {inputs.map((inputRef, index) => (
              <input
                key={index}
                className="m-2 border-2 border-gray-400 h-10 w-10 text-center form-control rounded"
                type="text"
                ref={inputRef}
                maxLength="1"
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
              />
            ))}
          </div>

          <div>
            <button
              onClick={handleVerify}
              className="bg-teal-600 text-white rounded-lg px-5 py-2.5 text-center mt-4"
            >
              Verify OTP
            </button>
          </div>

          <div className="flex justify-center text-center mt-5">
            <a className="flex items-center text-blue-700 hover-text-blue-900 cursor-pointer">
              <button onClick={handleResendOTP} className="font-bold">
                Resend OTP
              </button>
              <BsArrowRightShort />
            </a>
          </div>
          <button
            onClick={closeModal}
            className="bg-primary-600 text-white rounded-lg px-5 py-2.5 text-center mt-4"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
