import React from "react";
import { useState, useEffect } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const ViewPdf = (props) => {
  const [pdfFile, setPdfFile] = useState(props.file);
  // console.log("pdfFile on chat", pdfFile)

   // Update the pdfFile state whenever props.file changes
   useEffect(() => {
    setPdfFile(props.file);
  }, [props.file]);

  return (
    <div className="w-full h-screen  py-2">
      <div className=" h-full">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {pdfFile && (
            <>
              <Viewer fileUrl={pdfFile} />
            </>
          )}
          {!pdfFile && <>No Pdf</>}
        </Worker>
      </div>
    </div>
  );
};

export default ViewPdf;
