import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import YouTube from "react-youtube";

const YoutubeSummary = () => {
  const location = useLocation();
  console.log("YoutubeSummary", location);
  
  const { summary, videoId } = location.state || {};
    // console.log("summary", summary);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(summary);
    setCopySuccess(true);

    toast.success("Text Copied!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // Reset copy success message after 3 seconds
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 0, // Change to 1 if you want autoplay
    },
  };

  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.pauseVideo(); // For example, you can control the video here
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900 p-6">
      {summary && videoId ? (
        <div className="text-white text-center w-full max-w-6xl mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-8">
            {/* Video Section */}
            <div className="w-full md:w-1/2">
              <YouTube videoId={videoId} opts={opts} onReady={onReady} />
            </div>

            {/* Summary Section */}
            <div className="w-full md:w-1/2">
              <h1 className="text-4xl font-bold mb-4">Summary of YouTube Video</h1>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg overflow-y-auto max-h-96">
                {/* Preserve line breaks and white spaces */}
                <p className="whitespace-pre-wrap leading-relaxed text-lg text-gray-300">
                  {summary}
                </p>
              </div>

              {/* Copy Button */}
              <button
                onClick={handleCopy}
                className={`mt-4 px-6 py-3 font-medium rounded-lg bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
                  copySuccess ? "bg-green-600 hover:bg-green-700" : ""
                }`}
              >
                {copySuccess ? "Copied!" : "Copy Summary"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-white text-center">
          <h1 className="text-5xl">Summary Not Found...</h1>
        </div>
      )}
    </div>
  );
};

export default YoutubeSummary;
