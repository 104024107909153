import React from "react";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import blogData from "./BlogData";

const BlogDetails = () => {
  // Get the blog post index from the route params
  const { id } = useParams();
  const blog = blogData[id];

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <div>
      <main className="pt-8 pb-16 lg:pt-4 lg:pb-4 bg-gray-900 antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight  lg:mb-6 lg:text-4xl text-white">
                {blog.title}
              </h1>
            </header>
            <div className="border-8 border-pink-800">
              <img className="" src={blog.image} alt="" />
            </div>
            <address className="flex items-center justify-between mb-6 not-italic">
              <div className="inline-flex items-center justify-between mr-3 text-sm text-white">
                <img
                  className="mr-4 w-16 h-16 rounded-full"
                  src={blog.avatar}
                  alt={blog.author}
                />
                <a
                  href="#"
                  rel="author"
                  className="text-xl font-bold text-white"
                >
                  {blog.author}
                </a>
              </div>
              <div>
                <p className="text-base text-gray-400">
                  {blog.date}
                </p>
              </div>
            </address>
            <p className="text-white">{blog.content}</p>
            {blog.additionalContent.map((paragraph, pIndex) => (
              <div key={pIndex}>
                <h3 className="text-white">{paragraph.title}</h3>
                {paragraph.content.map((item, itemIndex) => (
                  <p  className="text-gray-400" key={itemIndex}>{item}</p>
                ))}
              </div>
            ))}
          </article>
        </div>
      </main>
    </div>
  );
};

export default BlogDetails;
