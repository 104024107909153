import React from "react";
import PdfUploadTopSection from "./PdfUploadTopSection";
import FamewallAnmation from "./FamewallAnmation";
import VideoSection from "./VideoSection";
import Navbar from "../Navbar/Navbar";
import WorkFlow from "./WorkFlow";

const Homepage = () => {
  return (
    <div className="w-full h-full bg-gray-900">
      <Navbar />
      <PdfUploadTopSection />
      <VideoSection />
      <WorkFlow />
      <FamewallAnmation />
    </div>
  );
};

export default Homepage;
