import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ViewPdf from "../../components/ViewPdf";
import { IoSendSharp } from "react-icons/io5";
import Menu from "./Menu";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";

const Chat = () => {
  const location = useLocation();
  const [messageData, setMessageData] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const chatContainerRef = useRef(null);

  // const scrollToBottom = () => {
  //   const bottomMessage = document.getElementById("bottom-message");
  //   if (bottomMessage) {
  //     bottomMessage.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  
  // console.log("location state", location.state);
  
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const pdf_key = localStorage.getItem("pdfKey");
  // if (!pdf_key) {
  //   console.log("No pdfKey found in localStorage");
  // } else {
  //   console.log("pdf key is ----", pdf_key);
  // }

  
  const getMessageData = async () => {
    if (!pdf_key) {
      return;
    }
    try {
      const res = await axios.get(
        process.env.REACT_APP_BASE_URL + `/api/v1/readpdf/chats/?pdfKey=${pdf_key}`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log("chat res data-----", res.data);
      if (res.data.chats && res.data.chats.length === 0) {
        console.log("No chats found for this PDF.");
        setMessageData([{ msg: "No chats found for the given PDF", client: false }]);
      } else {
        setMessageData([...res.data.chats]);
      }
      scrollToBottom();
    } catch (err) {
      console.log(err);
      // toast.error("Error retrieving the chats. Please try again later.");
    }
  };

  

  const chatResponse = async (data, client_message) => {
    const pdf_key = localStorage.getItem("pdfKey");
    try {
      setMessageData([
        ...data,
        {
          id: data.length,
          msg: (
            <div className="loader w-6 h-6 border-t-4 border-b-4 border-blue-500 border-solid rounded-full animate-spin"></div>
          ),
          client: false,
        },
      ]);

      setTimeout(() => {
        scrollToBottom();
      }, 0);

      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/qdrant-chat/",
        {
          client_message: client_message,
          pdf_key: pdf_key,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      const newMessage = {
        id: data.length,
        msg: "", // Start with an empty string
        client: false,
        questions:[],  // Initially empty, questions will come later
      };

// console.log("message data", res);

      // setMessageData([...data, newMessage]);
  
      // const text = res.data.msg;
      // let index = 0;
  
      // const interval = setInterval(() => {
      //   if (index < text.length) {
      //     setMessageData((prev) => {
      //       const updatedMessages = [...prev];
      //       updatedMessages[updatedMessages.length - 1].msg += text[index];
      //       return updatedMessages;
      //     });
      //     index++;
      //   } else {
      //     clearInterval(interval); // Stop when the text is fully displayed
      //   }
      //   scrollToBottom();
      // }, 20);

      setMessageData([...data, newMessage]);
  
      const text = res.data.msg;
      // Replace **text** with <strong>text</strong> for bold formatting
    const formattedText = text.replace(/\*\*(.*?)\*\*/g, (_, match) => `<strong>${match}</strong>`);

    // Function to render HTML content safely using React
    const renderFormattedText = (formattedText) => {
      return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    let index = 0;
    const interval = setInterval(() => {
      if (index < formattedText.length) {
        setMessageData((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[updatedMessages.length - 1].msg = renderFormattedText(formattedText.slice(0, index + 1));
          return updatedMessages;
        });
        index++;
        }else {
          clearInterval(interval);
  
          // Now that the message is fully rendered, update the questions field
          setMessageData((prev) => {
            const updatedMessages = [...prev];
            updatedMessages[updatedMessages.length - 1].questions =
              res.data.chat_questions ? res.data.chat_questions.split(";") : [];
            return updatedMessages;
          });
        }
        scrollToBottom();
      }, 20);
      // console.log("message data in function", messageData);
      
    } catch (err) {
      // Check for specific server error messages
      if (err.response?.data?.msg === "Payment required") {
        toast.warn("Plan Expired, Please upgrade your plan", {
          autoClose: 2000,
        });
      } else if (err.response?.data?.msg) {
        // Show server-defined error message
        toast.error(`Error: ${err.response.data.msg}`, {
          autoClose: 3000,
        });
      } else {
        // Show a generic error message
        toast.error("An error occurred while fetching the response. Please try again later.", {
          autoClose: 3000,
        });
      }
      // Log the error for debugging
      console.error("API Error:", err.response?.data || err.message);
    }
  };

  const handleInput = (something = null) => {
    const newMessageData = [
      ...messageData,
      {
        id: messageData.length,
        msg: something ? something : inputMessage,
        client: true,
      },
    ];
    setMessageData(newMessageData);
    chatResponse(newMessageData, something ? something : inputMessage);
    setInputMessage("");
    scrollToBottom();
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      handleInput();
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const remainingScroll =
        chatContainerRef.current.scrollHeight -
        chatContainerRef.current.scrollTop -
        chatContainerRef.current.clientHeight;

      if (remainingScroll <= 10) {
        setShowScrollButton(false);
      } else {
        setShowScrollButton(true);
      }
    }
  };

  useEffect(() => {
    getMessageData();
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    // Scroll to the bottom when the component initially loads
    scrollToBottom();

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // console.log("message data", messageData);
  

  return (
    <div className="chat-container flex flex-col md:flex-row justify-between h-fit md:h-screen w-full gap-2">

      {/* Menu Section */}
      <div className="menu-container hidden md:block w-14 flex-shrink-0">
        <Menu />
      </div>
  
  {/* Chat Section */}
  <div className="chatbot w-full md:w-7/10 h-full text-center flex flex-col rounded-md " >
  <div className="flex flex-col h-full overflow-y-auto" ref={chatContainerRef}>
    <div className="summary-container p-2 ">
      <div className="summary text-sm py-2 px-4 font-sans text-left bg-gray-800 text-gray-200 rounded-lg">
      <div style={{ whiteSpace: "pre-wrap" }}>
        <span dangerouslySetInnerHTML={{ 
         __html: (location.state.summary || "No summary available")
         .replace(/\*\*(.*?)\*\*/g, (_, match) => `<strong>${match}</strong>`) 
         .replace(/### (.*?)(\n|$)/g, (_, match) => `<h3>${match}</h3>`) 
        //  .replace(/- (.*?)(\n|$)/g, (_, match) => `<li>${match}</li>`)   
          }} />
          </div>
        <div className="mt-5">
          <p>Here are some sample questions :</p>
          <br />
          {location.state.questions && location.state.questions.length > 0 ? (
            location.state.questions.map((ele, index) => (
              <div
                key={index}
                onClick={(e) => handleInput(e.currentTarget.innerText)}
                className="flex gap-2 items-center py-[2px] cursor-pointer"
              >
                <IoSendSharp color="lightblue" />
                <p className="">{ele.question}</p>
              </div>
            ))
          ) : (
            <p>No questions available</p>
          )}
        </div>
      </div>
    </div>

    <div className="chat-container h-full flex flex-col justify-between">
  <div className="chats-container flex-grow flex flex-col gap-4 p-3">
    {messageData.length > 0 &&
      messageData?.map((ele) =>
        ele.client ? (
          <div className="w-full flex justify-end mb-2" key={ele.id}>
            <span
              className="px-4 py-2 bg-blue-500 rounded-lg text-white text-sm text-left"
              id={ele.id}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {ele.msg}
            </span>
          </div>
        ) : (
          <div key={ele.id}>
            {/* AI Response */}
            <div className="w-full flex justify-start mb-2">
              <span
                className="px-4 py-2 rounded-lg border-gray-300 bg-gray-700 text-white text-sm max-w-[85%] text-left"
                id={ele.id}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {ele.msg}
              </span>
            </div>

            {/* Render Suggested Questions in a Light Blue Box After the Answer */}
            <div className="mt-5">
              {ele.questions && ele.questions.length > 0 && (
                <div className="w-full flex justify-end mb-2">
                  <div className="flex flex-col bg-blue-500 text-white p-3 rounded-lg max-w-[85%] text-left">
                    <p>Here are some sample chat questions:</p>
                    <br />
                    {ele.questions.map((question, index) => (
                      <div
                        key={index}
                        onClick={() => handleInput(question)} // Handle click to ask the question
                        className="flex gap-2 items-center py-[2px] cursor-pointer text-white text-sm "
                      >
                        <p className="text-white">{index + 1}. {question}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      )}
  </div>
</div>



    </div>
    <div className="chat-input-box-container gap-1 flex justify-center items-center p-5">
      <input
        onKeyPress={handleInputKeyPress}
        placeholder="Ask me anything..."
        value={inputMessage}
        onInput={(e) => setInputMessage(e.target.value)}
        type="text"
        className="w-full h-12 bg-sky-100 rounded-3xl self-end py-1 px-3 placeholder-blue-500 focus:outline-none"
        
      />
      <button
        onClick={handleInput}
        className="border-2 bg-blue-500 w-10 rounded-md h-10 flex justify-center items-center"
      >
        <IoSendSharp color="white" />
      </button>
      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className="fixed right-4 bottom-16 bg-blue-500 text-white rounded-full cursor-pointer"
        >
          <BsFillArrowDownCircleFill size={22} />
        </button>
      )}
    </div>
  </div>

  {/* PDF View Section */}
  <div className="data-display w-full md:w-3/10 border-x-2 h-full text-center border-2 rounded-md border-gray-300">
    <ViewPdf file={location.state.file} />
  </div>
</div>

  );
};

export default Chat;