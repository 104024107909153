import React from "react";
import blogData from "./BlogData";
import { Link } from "react-router-dom";


// const blogPosts = [
//   {
//     title: "Mastering DocuSensa: 6 Pro Tips for Maximum Efficiency",
//     content:
//       "Unlock the full potential of DocuSensa by mastering these 6 professional tips. Enhance your productivity and make the most out of this robust tool.",
//     author: "Sophia Mitchell",
//     date: "10/10/2023",
//     avatar: "https://img.freepik.com/premium-vector/3d-avatar_665280-46961.jpg",
//   },
//   // {
//   //   title: "Conversing with PDFs: Unleash the Power of DocuSensa",
//   //   content:
//   //     "DocuSensa redefines the way you communicate with PDF documents. Learn the art of effectively using DocuSensa to interact with and extract data from any PDF file with ease.",
//   //   author: "Daniel Clark",
//   //   date: "10/10/2023",
//   //   avatar:
//   //     "https://img.freepik.com/premium-photo/teacher-man-avatar-icon-illustration-vector-style_131965-956.jpg",
//   // },
//   {
//     title: "Optimizing DocuSensa: A Guide to Streamlined Document Automation",
//     content:
//       "Take advantage of DocuSensa's document automation capabilities with this comprehensive guide. Discover how to streamline your tasks and automate processes with ease.",
//     author: "Emily Davis",
//     date: "10/10/2023",
//     avatar: "https://img.freepik.com/premium-vector/3d-avatar_665280-46961.jpg",
//   },
//   // {
//   //   title: "DocuSensa vs. Traditional PDF Software: An In-Depth Comparison",
//   //   content:
//   //     "Get an in-depth look at how DocuSensa stacks up against traditional PDF software. We evaluate features, usability, and performance to assist you in making an educated choice.",
//   //   author: "Alexander Turner",
//   //   date: "10/10/2023",
//   //   avatar:
//   //     "https://img.freepik.com/premium-photo/teacher-man-avatar-icon-illustration-vector-style_131965-956.jpg",
//   // },
// ];

const BlogPage = () => {
  return (
    <div>
      <section className="bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
            <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-white">
              Our Blog
            </h2>
            <p className="font-light  sm:text-xl text-gray-400">
              We aim to provide a comprehensive overview of our expertise on
              DocuSensa and Artificial Intelligence.
            </p>
          </div>
          <div className="grid gap-8 lg:grid-cols-2">
            {blogData.map((post, index) => (
              <article
                key={index}
                className="p-6  rounded-lg border border-gray-700 shadow-md bg-gray-800"
              >
                <div className="flex justify-between items-center mb-5 text-gray-500">
                  {/* <span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                    Tutorial
                  </span> */}
                  <span className="text-sm">{post.date}</span>
                </div>
                <h2 className="mb-2 text-2xl font-bold tracking-tight text-white">
                  <Link to={`/blog/${index}`}>{post.title}</Link>
                </h2>
                <p className="mb-5 font-light text-gray-400">
                  {post.content}
                </p>
                <div className="flex justify-between items-center">
                  <div className="flex items-center space-x-4">
                    <img
                      className="w-7 h-7 rounded-full"
                      src={post.avatar}
                      alt={`${post.author}'s avatar`}
                    />
                    <span className="font-medium text-white">
                      {post.author}
                    </span>
                  </div>
                  <Link
                    to={`/blog/${index}`}
                    className="inline-flex items-center font-medium text-primary-500 hover:underline"
                  >
                    Read more
                    <svg
                      className="ml-2 w-4 h-4"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </article>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogPage;
